/* global EM */
import ProjectionsFileBase from './ProjectionsFileBase';
import Dates from '../../util/Dates';
import _ from 'underscore';
import ScenariosFileDataStructure from './ScenariosFileDataStructure';

export default class ScenariosFile extends ProjectionsFileBase {
    constructor(data, entityName, id){
        super(data, entityName, id);

        this.onDataChange = this.onDataChange.bind(this);
        this.saveData = _.debounce(this.onDataSave.bind(this), 5000);
        this.dataManager = new ScenariosFileDataStructure(data, this.onDataChange);

        this.minMaxCache = {};
        this.preprocessedSchedule = null;   
        this.filterKeys = null;
        
        this.scenarioObject = EM.scenarios.byId(id);
        this.scheduleFile = EM.schedules.getFile(this.scenarioObject.ScheduleId);

        this.preprocessSchedule();

        if (!this.scheduleFile){
            console.log('Schedule not found. Load schedule first: ' + this.scenarioObject.ScheduleId);
        }
    }

    onDataChange(){
        this.saveData();
    }

    onDataSave(){
        console.log('Saving scenario.');
        EM.scenarios.saveFile(this.id, this.dataManager.serialize());
    }

    publish(name){
        let indexes = this.scheduleFile.indexes;
        let pairs = _.pairs(_.omit(indexes, 'attributes')); 
        if (indexes.attributes){
            pairs = [...pairs, ..._.pairs(indexes.attributes)];
        }
        let columns = _.sortBy(pairs, item => item[1]).map(item => item[0]);

        let output = [ columns.map(col => {
            if (col === 'workitem'){
                return 'Work Item';
            }else{
                return col.capitalize();
            }
        })];
        
        let snapshot = this.processSchedule();
        snapshot.flat.forEach(row => {
            if (row.$meta.isRemoved)return;
            output.push(columns.map(col => {                
                if (col === 'workitem'){
                    return row['Name'];
                }

                if (col === 'activity'){
                    return row['Activity'];
                }                

                if (col === 'begin'){
                    return row['Begin'].toISO();
                }  
                
                if (col === 'end'){
                    return row['End'].toISO();
                }  

                let value = row['_' + col];
                if (value)return value;

                return null;
            }));
        });

        return EM.schedules.create({
            Name: name,
            IsDefault: false,
            File: output
        });
    }

    getDefaultDateRange(){
        let tmp = this.minMaxCache['*'];
        return [ tmp.minOriginalBegin, tmp.maxOriginalEnd ];
    }

    setMinMax(item){
        let wi = item.Name;
        if (!this.minMaxCache[wi])this.minMaxCache[wi] = { minOriginalBegin: null, maxOriginalEnd: null };
        if (this.minMaxCache[wi].minOriginalBegin){
            if (item.Begin < this.minMaxCache[wi].minOriginalBegin)this.minMaxCache[wi].minOriginalBegin = item.Begin;
        }else{
            this.minMaxCache[wi].minOriginalBegin = item.Begin;
        }
        if (this.minMaxCache[wi].maxOriginalEnd){
            if (item.End > this.minMaxCache[wi].maxOriginalEnd)this.minMaxCache[wi].maxOriginalEnd = item.End;
        }else{
            this.minMaxCache[wi].maxOriginalEnd = item.End;
        }
    }

    preprocessSchedule(){
        let self = this;
        let processedItems = this.scheduleFile.toObjectArray((item, itemIndex) => {        
            if (!this.filterKeys)this.filterKeys = Object.keys(item);
            let begin = Dates.fromISO(item.Begin).startOf('month');
            let end = Dates.fromISO(item.End).endOf('month');
            if (begin.isInvalid || end.isInvalid)return null;
            if (begin>= end)return null;

            if (begin < Dates.usableBegin || end < Dates.usableBegin || begin > Dates.usableEnd || end > Dates.usableEnd){
                console.log('Dates out of range:', begin.toLocaleString(), end.toLocaleString());
                return null;
            }

            if (begin >= end){
                console.log('Dates out of order:', begin.toLocaleString(), end.toLocaleString());
                return null;
            }

            let beginRaw = item.Begin;
            let endRaw = item.End;
            item.Begin = begin;
            item.End = end;

            item.$id = itemIndex;

            this.setTitleAndMetaFromActivity(item, item.Activity);
            
            item.$original = Object.assign({}, item, {
                BeginRaw: beginRaw,
                EndRaw: endRaw,
                Begin: begin.plus({ months: 0 }),
                End: end.plus({ months: 0 }),
            });

            self.setMinMax(item);
            self.setMinMax(Object.assign({}, item, { Name: '*' }));
            return item;
        });
        return this.preprocessedSchedule = processedItems;
    }

    setTitleAndMetaFromActivity(item, activity){
        item.$title = activity
        item.$meta = {
            color: EM.activities.getColor(activity),
            isModified: false,
            isRemoved: false,
            isAdded: false
        };
    }

    processSchedule(preferences){
        let scheduleItems = this.preprocessedSchedule || this.preprocessSchedule();
        let outputItems = [];  

        scheduleItems.forEach((item, itemIndex) => {
            if (preferences){
                let isIncluded = this.isRowIncluded(item, preferences);
                if (!isIncluded)return;
            }
            
            let mod = this.dataManager.modifications.get(item.$id);
            let isRemoved = this.dataManager.removals.get(item.$id);            
            if (mod || isRemoved){                
                let mergedObject = Object.assign({}, item, mod);
                this.setTitleAndMetaFromActivity(mergedObject, mergedObject.Activity);
                mergedObject.$meta.isModified = !!mod;
                mergedObject.$meta.isRemoved = !!isRemoved;
                outputItems.push(mergedObject);
            }else{
                outputItems.push(item);
            }
        });

        let additions = this.dataManager.additions.get();
        Object.keys(additions).forEach(key => {
            let toAdd = Object.assign({}, additions[key]);
            if (preferences){
                let isIncluded = this.isRowIncluded(toAdd, preferences);
                if (!isIncluded)return;
            }
            this.setTitleAndMetaFromActivity(toAdd, toAdd.Activity);
            toAdd.$meta.isModified = false;
            toAdd.$meta.isAdded = true;
            outputItems.push(toAdd);
        });      

        let groups = outputItems.groupBy('Name');
        let keys = Object.keys(groups).sort();
        let output = [];
        let wiNames = [];

        keys.forEach((key, keyIndex) => {
            let items = groups[key]; 
            wiNames.push(key);
            let oKey = items[0].$original ? items[0].$original.Name : key;        
            let redProps = items.reduce((memo, item) => {
                memo.isModified = memo.isModified || item.$meta.isModified;
                memo.isRemoved = memo.isRemoved && item.$meta.isRemoved;
                memo.isAdded = memo.isAdded && item.$meta.isAdded;

                if (!memo.Begin){
                    memo.Begin = item.Begin;
                }else{
                    if (item.Begin < memo.Begin)memo.Begin = item.Begin;
                }

                if (!memo.End){
                    memo.End = item.End;
                }else{
                    if (item.End > memo.End)memo.End = item.End;
                }

                return memo;
            }, { isModified: false, isRemoved: true, isAdded: true, Begin: null, End: null });
            
            let tmp = {
                Name: key,
                Begin: redProps.Begin,
                End: redProps.End,                
                $id: 'Group-' + key,
                $title: key,
                $items: items,
                $meta: {
                    isModified: redProps.isAdded ? false : redProps.isModified,
                    isRemoved: redProps.isRemoved,
                    isAdded: redProps.isAdded
                }
            }
            
            if (!redProps.isAdded){
                tmp.$original = Object.assign({}, tmp, {
                    Begin: this.minMaxCache[oKey].minOriginalBegin,
                    End: this.minMaxCache[oKey].maxOriginalEnd,    
                });
            }

            output.push(tmp);
        });    
        return { grouped: output, flat: outputItems, wiNames: wiNames };
    }  

    isRowIncluded(row, preferences) {
        let keys = this.filterKeys;
        if (keys.length === 0) return true;

        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            let prefKey = key;
            if (key === 'Name')prefKey = 'workitem';
            if (key.indexOf('_') === 0)prefKey = key.slice(1);
            let acceptedValues = preferences.get(prefKey);
            if (!acceptedValues) continue;
            let rowValue = row[key];            
            if (acceptedValues.indexOf(rowValue) === -1) {
                return false;
            }
        }

        return true;
    }  

    changeItem(item, newProps){
        let isIndividualProps = Array.isArray(newProps);
        if (item.$meta.isAdded){
            this._applyToOneOrMore(item, (opItem, opItemIndex) => {
                this.dataManager.additions.merge(opItem.$id, isIndividualProps ? newProps[opItemIndex] : newProps);
            });   
        }else{            
            this._applyToOneOrMore(item, (opItem, opItemIndex) => {
                this.dataManager.modifications.merge(opItem.$id, isIndividualProps ? newProps[opItemIndex] : newProps);
            });   
        }   
    }

    resetItem(item){
        this._applyToOneOrMore(item, (opItem) => {
            this.dataManager.modifications.remove(opItem.$id);
        });      
    }

    removeItem(item){
        if (item.$meta.isAdded){
            this._applyToOneOrMore(item, (opItem) => {
                this.dataManager.additions.remove(opItem.$id);
            });   
        }else{
            this._applyToOneOrMore(item, (opItem) => {
                this.dataManager.removals.set(opItem.$id, true);
            });      
        }
    }

    createDuplicate(item, idSalt, newName){
        let output = {};
        Object.keys(item).forEach(key => {
            if (key.indexOf('$') === 0)return;
            output[key] = item[key];
        });
        output.$id = (new Date()).getTime() + idSalt;
        output.Name = newName;
        return output;
    }    

    duplicateItem(item, newName){
        this._applyToOneOrMore(item, (opItem, opItemIndex) => {
            let dupe = this.createDuplicate(opItem, opItemIndex, newName);
            this.dataManager.additions.set(dupe.$id, dupe);
        });      
    }

    restoreItem(item){
        this._applyToOneOrMore(item, (opItem) => {
            this.dataManager.removals.remove(opItem.$id);
        });      
    }

    restoreItems(ids){
        ids.forEach((id) => {
            this.dataManager.removals.remove(id);
        });        
    }

    _applyToOneOrMore(item, callback){
        if (item.$items){
            item.$items.forEach((subItem, subItemIndex) => callback(subItem, subItemIndex));
        }else{
            callback.bind(this)(item, 0);
        }
    }

    getRemovedItems(){
        let itemIndex = _.indexBy(this.preprocessedSchedule, '$id');
        let removals = Object.keys(this.dataManager.removals.get()).sort();
        let items = removals.map(id => {
            return itemIndex[id];
        });
        return items.groupBy('Name');
    }
}