/* eslint eqeqeq: "off" */
/* global EM */
import React, { Component } from 'react';
import StaffingWorkItemActivityDetail from './StaffingWorkItemActivityDetail';
import StaffingWorkItemRoleDetail from './StaffingWorkItemRoleDetail';
import Dates from '../../util/Dates';
import StaffingUtil from '../../util/StaffingUtil';
import MilestonesUtil from '../../util/MilestonesUtil';

export default class StaffingWorkItemRow extends Component{   
    constructor(props){
        super(props);

        this.state = {
            milestones: []
        }
    }

    componentDidMount(){        
        let milestones = MilestonesUtil.getMilestones(this.props.name);
        this.setState({ milestones });   
    }

    shouldComponentUpdate(nextProps, nextState){
        if (this.props.isActive !== nextProps.isActive || 
            this.props.onlyActive !== nextProps.onlyActive || 
            this.props.range !== nextProps.range ||
            this.props.detailMode !== nextProps.detailMode
        ){
            return true;
        }else{
            if (this.props.isActive || nextProps.isActive){
                return true;
            }else{
                return false;
            }                 
        }
    }

    render(){
        let { name, range, projections, assignments, isActive, onClick, maxWidth, filterIndexes, onlyActive,tasks } = this.props;
        let projectionDateGroups = {};
        if (projections){
            projections.forEach((projection) => {
                if (!projection[filterIndexes.dateKeys]){
                    return;
                }
                projection[filterIndexes.dateKeys].forEach(dateKey => {
                    if (!projectionDateGroups[dateKey])projectionDateGroups[dateKey] = 0;
                    projectionDateGroups[dateKey] += projection[filterIndexes.value];
                })
            });
        }

        let assignmentDateGroups = {};
        if (assignments){
            assignments.forEach((assignment) => {
                assignment.dateKeys.forEach(dateKey => {
                    if (!assignmentDateGroups[dateKey])assignmentDateGroups[dateKey] = 0;
                    assignmentDateGroups[dateKey] += assignment.Value;
                })
            });
        }     
        
        let marginBuffer = 0;
        let cellWidth = this.props.cellWidth;

        let doesOverlap = true;
        if (onlyActive){
            let allDatekeys = Object.keys(projectionDateGroups).sort();
            doesOverlap = Dates.doRangesOverlap(range.beginIndex, range.endIndex, allDatekeys[0], allDatekeys[allDatekeys.length - 1]);
        }   
                
        let milestonesIndex = this.state.milestones ? this.state.milestones.groupBy(ms => Dates.toMonthYearStr(ms.date)) : {};

        let renderedMilestones = [];

        if (!doesOverlap){
            return null;
        }else{
            return (
                <div className={"item " + (isActive ?'active':'')} onClick={onClick} id={name.toIdToken()}>
                    <div className="item-header" title={tasks?.label}>
                        {this.props.onWorkItemOpen && EM.isDomainEditor() && this.props.detailMode !== 'role' ? 
                            <button className="btn btn-light btn-sm" onClick={(event) => {
                                event.stopPropagation();
                                this.props.onWorkItemOpen(name);
                            }}>
                                <i className="fas fa-external-link-alt"></i>
                            </button>
                        : null }
                        {name}
                    </div>
                    {range.dates.map((month, monIndex) => { 
                        let output = [];
                        let dateKey = range.beginIndex + monIndex;
                        let projectionValue = Math.fround(projectionDateGroups[dateKey] || 0);
                        let assignmentValue = Math.fround(assignmentDateGroups[dateKey] || 0);                        
                        if (projectionValue > 0){
                            output.push(
                                <div className={"item-element fit " + StaffingUtil.getColorClass(assignmentValue, projectionValue, true) + (assignmentValue === 0 ? ' soft' : '') } 
                                    key={monIndex} 
                                    style={{ marginLeft: marginBuffer * cellWidth }} 
                                    title={StaffingUtil.getTooltip(assignmentValue, projectionValue)}>
                                    {StaffingUtil.getPercentage(assignmentValue, projectionValue)}%
                                </div>
                            );
                            marginBuffer = 0;
                        }else{
                            marginBuffer++;
                        }

                        if (isActive){
                            let myStr = Dates.toMonthYearStr(month);      
                            let milestoneFlags = null;
                            if (milestonesIndex[myStr]){
                                milestoneFlags = milestonesIndex[myStr].map(ms => {
                                    let offset = (ms.date.day / 30) * cellWidth;
                                    let left = (monIndex * cellWidth) + offset + StaffingUtil.getHeaderWidth();
                                    return <div className="milestone-flag" key={ms.id} title={ms.label  + ': ' + ms.date.toLocaleString()} style={{ left: left + 'px' }}>
                                        <div>
                                            <span>{ms.label.trim()}</span>
                                        </div>
                                    </div>
                                });
                                renderedMilestones.push(milestoneFlags);
                            }   
                        }

                        return output;
                    })}
                    {isActive ?
                        <>
                            {this.props.detailMode === 'role' ?
                                <StaffingWorkItemRoleDetail
                                    cellWidth={cellWidth}
                                    rowHeight={this.props.rowHeight}
                                    filterIndexes={filterIndexes} 
                                    isActive={isActive}
                                    projections={projections}
                                    assignments={assignments}
                                    range={range}
                                    maxWidth={maxWidth}
                                    onAddAssignment={this.props.onAddAssignment}
                                    onAfterDeleteAssignment = {this.props.onAfterDeleteAssignment}
                                    onAfterUpdateAssignment = {this.props.onAfterUpdateAssignment}   
                                    milestones={renderedMilestones}    
                                    focusRoles={this.props.focusRoles}                    
                                />
                            : 
                                <StaffingWorkItemActivityDetail
                                    cellWidth={cellWidth}
                                    rowHeight={this.props.rowHeight}
                                    filterIndexes={filterIndexes} 
                                    isActive={isActive}
                                    projections={projections}
                                    assignments={assignments}
                                    range={range}
                                    maxWidth={maxWidth}
                                    onAddAssignment={this.props.onAddAssignment}
                                    onAfterDeleteAssignment = {this.props.onAfterDeleteAssignment}
                                    onAfterUpdateAssignment = {this.props.onAfterUpdateAssignment}   
                                    milestones={renderedMilestones}    
                                    focusRoles={this.props.focusRoles}                    
                                />
                            }
                        </>
                    : null }
                </div>
            );
        }
    }
}