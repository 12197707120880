/* global EM */
/* eslint jsx-a11y/anchor-is-valid: "off" */
import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { Button, ButtonGroup } from 'reactstrap';
import Logo from "../images/logo.svg";
import Routes from "../app/Routes";
import { matchPath } from 'react-router';
import UserIcon from './UserIcon';
import Select from 'react-select';
import NotificationsContainer from './Notifications/NotificationsContainer'

import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem,
    NavLink as RsNavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import TrainingHeaderAdditions from "./TrainingHeaderAdditions";


class Header extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false
        };

        this.closeMenus = this.closeMenus.bind(this);

        if (!EM.history) EM.setHistory(this.props.history);

    }



    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    closeMenus() {
        var elem = document.getElementById('root');
        if (elem) elem.click();
        this.setState({ isOpen: false });
    }

    render() {
        const isMgmt = !!matchPath(this.props.location.pathname, Routes.client.management);
        const isHelp = !!matchPath(this.props.location.pathname, Routes.client.help) || !!matchPath(this.props.location.pathname, Routes.client.manual);
        const isSettings = !!matchPath(this.props.location.pathname, Routes.client.settings);
        const isDashboard = !!matchPath(this.props.location.pathname, Routes.client.dashboard);
        const isDemand = !!matchPath(this.props.location.pathname, Routes.client.demand) || !!matchPath(this.props.location.pathname, Routes.client.dataExplorer);
        const isStaffing = !!matchPath(this.props.location.pathname, Routes.client.staffing);
        const isAdmin = !!matchPath(this.props.location.pathname, Routes.admin.home);
        const isScenario = !!matchPath(this.props.location.pathname, Routes.client.scenarios) || !!matchPath(this.props.location.pathname, Routes.client.scenarioViewer);
        const isOrg = !!matchPath(this.props.location.pathname, Routes.client.organization) || !!matchPath(this.props.location.pathname, Routes.client.departments) || !!matchPath(this.props.location.pathname, Routes.client.roles);
        let domain = this.props.domain ? this.props.domain.Name : '';
        return (
            <Navbar className={"mb-3 " + (isAdmin ? "admin " : "") + (this.props.domain ? "domain" : "")} color="dark" dark expand="md" role="navigation">
                <NavLink exact={true} className={"navbar-brand " + (this.props.domain ? 'small' : '')} to="/" onClick={() => {
                    EM.clearActiveDomain();
                }}>
                    <img src={Logo} alt="Logo" />
                </NavLink>
                <NavbarToggler onClick={this.toggle} />
                <Collapse isOpen={this.state.isOpen} navbar>
                    {this.props.domain ?
                        <div className="domain-header">
                            <NavLink to="/" onClick={() => {
                                EM.clearActiveDomain();
                            }}>{this.props.account.Tenant.DisplayName}</NavLink>
                            <div>{this.props.domain.DisplayName}</div>
                        </div>
                        : null}
                    {isHelp || isAdmin ?
                        <Nav className="navbar-nav mr-auto nav-main domain-menu">
                            <NavItem className="icon-only">
                                <NavLink exact={true} className="nav-link" to="/" title={EM.t('header.home')}>
                                    <i className="fas fa-home"></i>
                                </NavLink>
                            </NavItem>
                        </Nav>
                        : null}
                    {this.props.domain ?
                        <Nav className="navbar-nav mr-auto nav-main domain-menu">
                            <NavItem>
                                <NavLink exact={true} className={"nav-link " + (isDashboard ? 'active' : '')} to={Routes.compose(Routes.client.dashboard, { DomainName: domain })}>
                                    {EM.t('header.dashboard')}
                                </NavLink>
                            </NavItem>
                            {EM.hasFeature('dataExplorer') ?
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav caret className={isDemand ? 'active' : ''}>
                                        {EM.t('header.demand')}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <NavLink exact={true} className="dd-link" to={Routes.compose(Routes.client.demand, { DomainName: domain })} onClick={this.closeMenus}>
                                            {EM.t('header.demandVisualization')}
                                        </NavLink>
                                        <NavLink exact={true} className="dd-link alpha" to={Routes.compose(Routes.client.dataExplorer, { DomainName: domain })} onClick={this.closeMenus}>
                                            {EM.t('header.dataExplorer')}
                                        </NavLink>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                :
                                <NavItem>
                                    <NavLink exact={true} className={"nav-link "} to={Routes.compose(Routes.client.demand, { DomainName: domain })} onClick={this.closeMenus}>
                                        {EM.t('header.demand')}
                                    </NavLink>
                                </NavItem>
                            }
                            {EM.hasFeature('staffing') ?
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav caret className={isStaffing ? 'active' : ''}>
                                        {EM.t('header.staffing')}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <NavLink exact={true} className="dd-link" to={Routes.compose(Routes.client.staffingEmployee, { DomainName: domain })} onClick={this.closeMenus}>
                                            {EM.t('header.staffingEmployee')}
                                        </NavLink>
                                        <NavLink exact={true} className="dd-link" to={Routes.compose(Routes.client.staffingWorkItem, { DomainName: domain, DetailMode: 'activity' })} onClick={this.closeMenus}>
                                            {EM.t('header.staffingWorkItemActivity')}
                                        </NavLink>
                                        <NavLink exact={true} className="dd-link alpha" to={Routes.compose(Routes.client.staffingWorkItem, { DomainName: domain, DetailMode: 'role' })} onClick={this.closeMenus}>
                                            {EM.t('header.staffingWorkItemRole')}
                                        </NavLink>                                        
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                : null}
                            {EM.hasFeature('scenarios') && EM.isDomainEditor() ?
                                <NavItem className="nav-item">
                                    <NavLink exact={true} className={"nav-link " + (isScenario ? 'active' : '')} to={Routes.compose(Routes.client.scenarios, { DomainName: domain, ScenarioId: null })} onClick={this.closeMenus}>
                                        {EM.t('header.scenarios')}
                                    </NavLink>
                                </NavItem>
                                : null}
                            {EM.isDomainEditor() ?
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav caret className={isMgmt ? 'active' : ''}>
                                        {EM.t('header.management')}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <NavLink exact={true} className="dd-link" to={Routes.compose(Routes.client.schedules, { DomainName: domain })} onClick={this.closeMenus}>
                                            {EM.t('header.schedules')}
                                        </NavLink>

                                        <NavLink exact={true} className="dd-link" to={Routes.compose(Routes.client.headcounts, { DomainName: domain })} onClick={this.closeMenus}>
                                            {EM.t('header.headcounts')}
                                        </NavLink>

                                        {EM.hasFeature('actuals') ?
                                            <NavLink exact={true} className="dd-link" to={Routes.compose(Routes.client.actuals, { DomainName: domain })} onClick={this.closeMenus}>
                                                {EM.t('header.actuals')}
                                            </NavLink>
                                            : null}

                                        <DropdownItem divider />

                                        <NavLink exact={true} className="dd-link" to={Routes.compose(Routes.client.allocations, { DomainName: domain })} onClick={this.closeMenus}>
                                            {EM.t('header.allocations')}
                                        </NavLink>

                                        <NavLink exact={true} className="dd-link" to={Routes.compose(Routes.client.complexities, { DomainName: domain })} onClick={this.closeMenus}>
                                            {EM.t('header.complexities')}
                                        </NavLink>

                                        <NavLink exact={true} className="dd-link" to={Routes.compose(Routes.client.algorithms, { DomainName: domain })} onClick={this.closeMenus}>
                                            {EM.t('header.algorithms')}
                                        </NavLink>

                                        <NavLink exact={true} className="dd-link" to={Routes.compose(Routes.client.attributes, { DomainName: domain })} onClick={this.closeMenus}>
                                            {EM.t('header.attributes')}
                                        </NavLink>

                                        <NavLink exact={true} className={'dd-link ' + (isOrg ? 'active' : '')} to={Routes.compose(Routes.client.organizations, { DomainName: domain })} onClick={this.closeMenus}>
                                            {EM.t('header.organization')}
                                        </NavLink>

                                        <NavLink exact={true} className="dd-link" to={Routes.compose(Routes.client.activities, { DomainName: domain })} onClick={this.closeMenus}>
                                            {EM.t('header.activities')}
                                        </NavLink>

                                        {EM.hasFeature('staffing') ?
                                            <NavLink exact={true} className="dd-link" to={Routes.compose(Routes.client.employees, { DomainName: domain })} onClick={this.closeMenus}>
                                                {EM.t('header.employees')}
                                            </NavLink>
                                            : null}

                                        {EM.hasFeature('staffing') && EM.isDomainAdmin() ?
                                            <NavLink exact={true} className="dd-link" to={Routes.compose(Routes.client.assignments, { DomainName: domain })} onClick={this.closeMenus}>
                                                {EM.t('header.assignments')}
                                            </NavLink>
                                            : null}

                                        {EM.isDomainAdmin() ?
                                            <>
                                                <DropdownItem divider />
                                                <NavLink exact={true} className={'dd-link ' + (isSettings ? 'active' : '')} to={Routes.compose(Routes.client.generalSettings, { DomainName: domain })} onClick={this.closeMenus}>
                                                    <i className="fas fa-cog"></i>{EM.t('header.settings')}
                                                </NavLink>
                                            </>
                                            :
                                            <>
                                                {EM.isDomainEditor() ?
                                                    <>
                                                        <DropdownItem divider />
                                                        <NavLink exact={true} className={'dd-link ' + (isSettings ? 'active' : '')} to={Routes.compose(Routes.client.referenceTables, { DomainName: domain })} onClick={this.closeMenus}>
                                                            <i className="fas fa-cog"></i>{EM.t('header.settings')}
                                                        </NavLink>
                                                    </>
                                                    : null}
                                            </>
                                        }
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                : null}
                            {EM.isDomainEditor() && EM.isDomainDirty() ?
                                <UncontrolledDropdown nav inNavbar className="warning">
                                    <DropdownToggle nav className={'menu'} title={EM.t('header.dirtyWarning')}>
                                        <i className="fas fa-exclamation-circle"></i>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <NavLink className={"dd-link"} to={Routes.compose(Routes.client.schedules, { DomainName: domain })} onClick={this.closeMenus}>
                                            {EM.t('header.goToSchedules')}
                                        </NavLink>
                                        <DropdownItem divider />
                                        <a className="dd-link" onClick={() => EM.markDomainClean(this.props.domain.DomainId)}>
                                            {EM.t('header.dismiss')}
                                        </a>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            : null}                            
                        </Nav>
                        : null}
                    <Nav className="navbar-nav flex-row ml-md-auto nav-util tighter">
                        {this.props.domain ?
                            <TrainingHeaderAdditions settings={EM.settings.get()}/>
                        : null }
                        {EM.isTenantAdmin() ?
                            <UncontrolledDropdown nav inNavbar className="icon-only">
                                <DropdownToggle nav className={'menu'} title={EM.t('header.admin.home')}>
                                    <i className="fas fa-user-shield"></i>
                                </DropdownToggle>
                                <DropdownMenu right>
                                    {EM.isSuperAdmin() ?
                                        <>
                                            <NavLink exact={true} className={"dd-link"} to={Routes.compose(Routes.admin.tenants)} onClick={this.closeMenus}>
                                                {EM.t('header.admin.tenants')}
                                            </NavLink>
                                            <DropdownItem divider />
                                        </>
                                        : null}
                                    <DropdownItem header>
                                        {EM.me.Tenant.DisplayName}
                                    </DropdownItem>
                                    <NavLink exact={true} className={"dd-link indent"} to={Routes.compose(Routes.admin.domains)} onClick={this.closeMenus}>
                                        {EM.t('header.admin.domains')}
                                    </NavLink>
                                    <NavLink exact={true} className={"dd-link indent"} to={Routes.compose(Routes.admin.users)} onClick={this.closeMenus}>
                                        {EM.t('header.admin.users')}
                                    </NavLink>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        : null}
                        {!isHelp ?
                            <NavItem className="icon-only" title={EM.t('header.help')}>
                                <RsNavLink className={"dd-link help-toggle " + (this.props.isHelpMode ? 'active' : '')} onClick={() => {
                                    this.closeMenus();
                                    this.props.onHelpClick();
                                }}>
                                    <i className="fas fa-question bg"></i>
                                </RsNavLink>
                            </NavItem>
                            : null}
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav caret>
                                <UserIcon user={this.props.account.User} />
                                <span className="mobile-only">{this.props.account.Name}</span>
                                {this.props.notifications.length === 0 ?
                                    null
                                    : <i className="notification-indicator fas fa-bell text-danger"></i>}
                            </DropdownToggle>
                            <DropdownMenu right>
                                {this.props.notifications.length === 0 ?
                                    null
                                    : <NotificationsContainer notifications={this.props.notifications} />}
                                {EM.languageList.length > 1 ?
                                    <Select
                                        className="single-select"
                                        classNamePrefix="single-select"
                                        defaultValue={EM.languageList.find(lng => lng.value === EM.currentLanguage)}
                                        options={EM.languageList}
                                        isSearchable={false}
                                        placeholder={EM.t('util.filtering.placeholderDefault')}
                                        onChange={(selectedItem) => {
                                            EM.storage.set('lang', selectedItem.value);
                                            window.location.reload();
                                        }}
                                    />
                                    : null}
                                <ButtonGroup>
                                    <Button className="theme-toggle" active={EM.currentTheme === 'Light'} onClick={(e) => {
                                        EM.setTheme('Light', e);
                                    }}>{EM.t('options.light')}</Button>
                                    <Button className="theme-toggle" active={EM.currentTheme === 'Dark'} onClick={(e) => {
                                        EM.setTheme('Dark', e);
                                    }}>{EM.t('options.dark')}</Button>
                                </ButtonGroup>
                                {EM.authenticator.mode === 'Enterprise' ?
                                    <a className="dd-link" onClick={EM.authenticator.switchAccount}>
                                        {EM.t('header.switchAccount')}
                                    </a>
                                    : null}
                                <a className="dd-link" onClick={EM.authenticator.logout}>
                                    {EM.t('header.logout')}
                                </a>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </Nav>
                </Collapse>
            </Navbar>
        );
    }


}

export default Header;