import PreferenceManager from './PreferenceManager';

export default class DemandPreferences{
    constructor(name, inst, page){
        this.name = name;
        this.preferences = new PreferenceManager(page || 'demand');
        this.group = this.preferences.asGroup('preferences'); 
        this.inst = inst ? inst : (this.group(name) || {});     
        
        this.primaryInst = new DemandPreferencesDataset('primary', this, this.inst.primary);
        this.secondaryInst = new DemandPreferencesDataset('secondary', this, this.inst.secondary);
        this.tertiaryInst = new DemandPreferencesDataset('tertiary', this, this.inst.tertiary);
    }

    set(name, value){
        let newOptions = Object.assign({}, this.inst, { [name]: value });
        if (Array.isArray(value)) {
            if (value.length === 0) {
                delete newOptions[name];
            }
        }    
        
        this.inst = newOptions;
        this.group(this.name, newOptions);
    }

    get(name, defaultValue){
        let val = this.inst[name] || null;
        return val || defaultValue;
    }

    clone(){
        return new DemandPreferences(this.name, this.inst);
    }

    //////////Helpers for specific fields
    get begin() {
        return this.get('begin');
    }
    set begin(value) {
        this.set('begin', value);
    }    

    get end() {
        return this.get('end');
    }
    set end(value) {
        this.set('end', value);
    }       

    get grouping() {
        return this.get('grouping', 'Role');
    }
    set grouping(value) {
        this.set('grouping', value);
    }      

    get dateGrouping() {
        return this.get('dateGrouping', 'Month');
    }
    set dateGrouping(value) {
        this.set('dateGrouping', value);
    }    

    get focusMode() {
        return this.get('focusMode', false);
    }
    set focusMode(value) {
        this.set('focusMode', value);
    }   
    
    get plotLines() {
        return this.get('plotLines', false);
    }
    set plotLines(value) {
        this.set('plotLines', value);
    }       
    
    get primary(){
        return this.primaryInst;
    }

    get secondary(){
        return this.secondaryInst;
    }

    get tertiary(){
        return this.tertiaryInst;
    }

    get role() {
        return this.get('role', null);
    }
    set role(value) {
        this.set('role', value);
    }  

    get department() {
        return this.get('department', null);
    }
    set department(value) {
        this.set('department', value);
    }  

    get organization() {
        return this.get('organization', null);
    }
    set organization(value) {
        this.set('organization', value);
    }  
}

class DemandPreferencesDataset{
    constructor(name, parent, inst){
        this.parent = parent;
        this.name = name;
        this.inst = inst || {};  
    }

    set(name, value){
        let newOptions = Object.assign({}, this.inst, { [name]: value });
        if (Array.isArray(value)) {
            if (value.length === 0) {
                delete newOptions[name];
            }
        }    
        
        this.inst = newOptions;
        this.parent.set(this.name, newOptions);
    }

    get(name, defaultValue){
        let val = this.inst[name] || null;
        return val || defaultValue;
    }    

    get dataset(){
        return this.get('dataset', null);
    } 
    set dataset(value) {
        this.set('dataset', value);
    }      
    
    get reserve(){
        return this.get('reserve', false);
    } 
    set reserve(value) {
        this.set('reserve', value);
    }  

    get attrition(){
        return this.get('attrition', false);
    }  
    set attrition(value) {
        this.set('attrition', value);
    }  

    get pos(){
        return this.get('pos', false);
    }  
    set pos(value) {
        this.set('pos', value);
    }      

    get chartType(){
        return this.get('chartType', 'Area');
    }      
    set chartType(value) {
        this.set('chartType', value);
    }  
    
    get totalOnly(){
        return this.get('totalOnly', false);
    }
    set totalOnly(value) {
        this.set('totalOnly', value);
    }    
}
