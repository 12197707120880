export default {
    client: {
        home: '/',
        notFound: '/404',
        help: '/support',
        manual: '/manual',
        domain: '/:DomainName',
        visualizations: '/:DomainName/visualizations',
        demand: '/:DomainName/visualizations/demand',
        dataExplorer: '/:DomainName/visualizations/data-explorer',
        scenarioViewer: '/:DomainName/visualizations/scenario-view/:ScenarioId',
        scenarios: '/:DomainName/visualizations/scenarios',
        staffing: '/:DomainName/visualizations/staffing',
        staffingEmployee: '/:DomainName/visualizations/staffing/employee',
        staffingWorkItem: '/:DomainName/visualizations/staffing/workitem/:DetailMode',
        dashboard: '/:DomainName/dashboard',
        demandReport: '/:DomainName/reports/demand',
        staffingReport: '/:DomainName/reports/staffing',
        management: '/:DomainName/management',
        schedules: '/:DomainName/management/schedules',
        activities: '/:DomainName/management/activities',
        allocations: '/:DomainName/management/allocations',
        complexities:  '/:DomainName/management/complexities',
        organizations:  '/:DomainName/management/organizations',
        departments:  '/:DomainName/management/departments',
        roles:  '/:DomainName/management/roles',
        attributes:  '/:DomainName/management/attributes',
        algorithms:  '/:DomainName/management/algorithms',
        headcounts:  '/:DomainName/management/headcounts',
        actuals:  '/:DomainName/management/actuals',
        employees:  '/:DomainName/management/employees',
        assignments:  '/:DomainName/management/assignments',
        settings:  '/:DomainName/management/settings',
        generalSettings:  '/:DomainName/management/settings/general',
        advancedSettings:  '/:DomainName/management/settings/advanced',
        security:  '/:DomainName/management/settings/security',
        referenceTables: '/:DomainName/management/settings/referenceTables',
        pipelinesOld: '/:DomainName/management/settings/pipelines-old',
        pipelines: '/:DomainName/management/settings/pipelines',
        pipelineViewer: '/:DomainName/management/settings/pipeline-view/:ConfigurationId'
    },
    clientApi: {
        account: 'account',
        users:'users/user',
        translation: '%PUBLIC_URL%/i18n/:Lang.json',
        help: 'docs/:Lang/:Name?cid=:CID&meta=:IsMeta',
        domainEntity: 'data/:DomainId/:Entity',
        domainEntityImport: 'data/:DomainId/:Entity/import',
        domainEntityFile: 'file/:DomainId/:Entity/:Id',
        domainEntityClear: 'data/:DomainId/:Entity/clear',
        projections: 'projections/:DomainId/:ScheduleId',
        auditEntity: 'audit/:DomainId/:Entity/:EntityId',
        domainTemplateCreate: 'template/:DomainId',
        domainCreateFromTemplate: 'template',
        notify: 'notify',
        proxy: 'proxy'
    },
    admin: {
        home: '/administration',
        tenants: '/administration/tenants',
        domains: '/administration/domains',
        users: '/administration/users',
    },
    adminApi: {
        adminEntity: 'management/:TenantId/:Entity',
        adminEntityImport: 'management/:TenantId/:Entity/import',
        switchTenant: 'management/*/tenants/switch',
        uptimeReport: 'management/*/uptime'
    },
    compose: function(url, params, qs) {
        var output = url.replace('%PUBLIC_URL%', process.env.PUBLIC_URL);
        if (params){
            Object.keys(params).forEach(function (key, index) {
                let value = params[key];
                output = output.replace(':' + key, value);
            });
        }
        if (!qs) return output;    
        if (typeof qs === 'string'){
            return output + '?' + qs;
        }else{
            return output + '?' + Object.keys(qs).map(key => key + '=' + qs[key]).join('&');
        }
    },
    parseQuery(queryString) {
        let toParse = queryString || window.location.search.slice(1);
        let query = {};
        let pairs = toParse.split('&');
        for (let i = 0; i < pairs.length; i++) {
            let pair = pairs[i].split('=');
            query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
        }
        return query;
    }
}