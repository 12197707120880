/* global EM */
import React, { Component } from 'react';
import { Nav, NavItem, NavLink as BsNavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import ReportListingSection from '../components/ReportListingSection';
import ReportListingConfigurations from '../components/ReportListingConfigurations';
import PageTitle from '../components/PageTitle';
import EmployeeAvailabilityWidget from '../components/Widgets/EmployeeAvailabilityWidget';
import WorkItemsEndingWidget from '../components/Widgets/WorkItemsEndingWidget';
import WorkItemsNoAssignmentsWidget from '../components/Widgets/WorkItemsNoAssignmentsWidget';

export default class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: 'demand'
        }

        this.onTabChange = this.onTabChange.bind(this);

        EM.configurations.load();
        EM.configurationTypeLookup.load();
    }

    onTabChange(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    render() {
        let domain = EM.getActiveDomain();    
        let dashboardSetting = EM.getSetting('DashboardWidgets');
        let dashboards = [];        
        if (dashboardSetting){
            let bits = dashboardSetting.split(',');
            if (bits.length > 0){
                bits.forEach(bit => {
                    if (bit === 'EmployeeAvailabilityWidget'){
                        dashboards.push(<EmployeeAvailabilityWidget />);
                    }
                    if (bit === 'WorkItemsEndingWidget'){
                        dashboards.push(<WorkItemsEndingWidget />);
                    }
                    if (bit === 'WorkItemsNoAssignmentsWidget'){
                        dashboards.push(<WorkItemsNoAssignmentsWidget />);
                    }

                });
            }
            
        }
        
        
        return (
            <div key="contents" className="page page-bg container-fluid page-dashboard">
                <div className="page-header">
                    <div className="container">
                        <PageTitle icon="far fa-cube" title={domain.DisplayName} />
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className={dashboards.length > 0 ? 'col-md-8' : 'col-md-12'}>
                            <h4>{EM.t('reports.saved.title')}</h4>
                            <Nav tabs>
                                <NavItem>
                                    <BsNavLink className={classnames({ active: this.state.activeTab === 'demand' })}
                                        onClick={() => { this.onTabChange('demand'); }}
                                    >{EM.t('reports.tabs.demand')}</BsNavLink>
                                </NavItem>
                                <NavItem>
                                    <BsNavLink className={classnames({ active: this.state.activeTab === 'staffing' })}
                                        onClick={() => { this.onTabChange('staffing'); }}
                                    >{EM.t('reports.tabs.staffing')}</BsNavLink>
                                </NavItem>
                            </Nav>
                            <TabContent className="" activeTab={this.state.activeTab}>
                                <TabPane tabId="demand">
                                    <ReportListingConfigurations title={EM.t('reports.saved.title')} typeId={1} domain={this.props.domain} />
                                    <ReportListingSection name="demand" domain={this.props.domain} title={EM.t('reports.demand.title')} />
                                </TabPane>
                                <TabPane tabId="staffing">
                                    <ReportListingConfigurations title={EM.t('reports.saved.title')} typeId={2} domain={this.props.domain} />
                                </TabPane>
                            </TabContent>
                        </div>
                        <div className="col-md-4">
                            {dashboards.map((widget, wI) => {
                                return (
                                    <div className="mb-4" key={'widget' + wI}>
                                        {widget}                                    
                                    </div>
                                )
                            })}                        
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
