/* global EM */
import React, { Component } from 'react';
import { Switch, Route } from "react-router-dom";
import Routes from '../app/Routes';
import _ from 'underscore';

import Visualizations from './Visualizations';
import Management from './Management';
import Dashboard from './Dashboard';
import DemandReport from './DemandReport';
import StaffingReport from './StaffingReport';

class Domain extends Component {
    constructor(props){
        super(props);

        this.state = {
            domain: null
        }
    }

    componentDidMount(){
        var self = this;
        var domainName = this.props.match.params.DomainName;
        var domain = _.find(this.props.account.Domains, (domain) => {
            return domain.Name === domainName;
        });

        if (domain){
            EM.setActiveDomain(domain).then(() => {
                self.setState({ domain: domain });
            });
        }else{
            this.props.history.push(Routes.client.notFound);
        }
    }

    render() {    
        if (!this.state.domain)return null;

        return (            
            <Switch>                
                <Route path={Routes.client.visualizations}  render={() => <Visualizations {...this.props} />} />
                <Route path={Routes.client.management}  render={() => <Management {...this.props} />} />              
                <Route path={Routes.client.demandReport} render={() => <DemandReport {...this.props} />} />                
                <Route path={Routes.client.staffingReport} render={() => <StaffingReport {...this.props} />} />
                <Route path={Routes.client.dashboard} render={() => <Dashboard {...this.props} />} />
            </Switch>  
        );
    }
}

export default Domain;
