/* global EM */
import React, { Component } from 'react';
import Select from 'react-select';

const customMultiValueLabel = ({ data, innerProps }) => (
    <div className={innerProps.className} title={data.label}>{ data.label }</div>
);

export default class FilterSelection extends Component {
    constructor(props){
        super(props);

        this.onChange = this.onChange.bind(this);

        this.optionsDpKey = this.props.label + '-Options';
        EM.clearDpCacheItem(this.optionsDpKey);
    }

    onChange(values){
        let items = values ? values.map(item => { return item.value }) : [];
        this.props.onChange(items);
    }

    render() {
        let options = this.props.options ? EM.fromDpCache(this.optionsDpKey, () => {
            return this.props.options.map((item) => {
                return { value: item, label: item || '<blank>' };
            })
        }) : [];

        let selected = this.props.selected ? this.props.selected.map((item) => {
            return { value: item, label: item|| '<blank>' };
        }) : [];

        return (
            <div className="form-group">
                <label>{this.props.label}:</label>
                <Select
                    className="multi-select"
                    classNamePrefix="multi-select"
                    isSearchable={true}
                    isMulti={true}
                    value={selected}
                    options={options}
                    placeholder={EM.t('util.filtering.placeholder')}
                    onChange={this.onChange}
                    isDisabled={this.props.disabled}
                    components={ { MultiValueLabel: customMultiValueLabel } }
                />
            </div>
        );
    }
};