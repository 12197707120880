/* global EM */
import { Type } from 'react-bootstrap-table2-editor';
import ActiveEditField from './ActiveEditField';
import PercentageEditField from './PercentageEditField';
import DateEditField from './DateEditField';
import PopoutEditField from './PopoutEditField';
import WorkItemSelector from './WorkItemSelector';
import UserIcon from '../components/UserIcon';
import AssignmentStatusEditField from './Staffing/AssignmentStatusEditField';
import AssignmentLabelEditField from './Staffing/AssignmentLabelEditField';
import React from 'react';
import { Validate } from '../util/EntityValidators';
import Dates from '../util/Dates';
import ExpressionLocal from '../util/ExpressionLocal';
import { textFilter, selectFilter, numberFilter, dateFilter, Comparator } from 'react-bootstrap-table2-filter';
const ExpressionEditField = React.lazy(() => import('./Expressions/ExpressionEditField'));

export default function (columns, registerFilter) {
    return columns.map((columnDef, columnIndex) => {
        var newDef = Object.assign({}, columnDef);
        if (!newDef.hasOwnProperty('sort')) {
            newDef.sort = true;
        }

        if (newDef.fromEntity && !newDef.formatter && !newDef.editor) {
            newDef.editor = {
                type: Type.SELECT,
                options: newDef.allowNulls ? [{ value: null, label: null }, ...newDef.fromEntity.asOptionList(null, newDef.omitEntitySort ? false : true)] : newDef.fromEntity.asOptionList(null, newDef.omitEntitySort ? false : true)
            };

            newDef.formatter = newDef.sortValue = newDef.csvFormatter = (cell, row) => {
                return newDef.fromEntity.lookupValue(cell);
            };

            if (registerFilter) {
                newDef.filterValue = (cell) => {
                    return cell;
                };

                let selectFilterOptions = {
                    getFilter: (filter) => { if (registerFilter) registerFilter(filter); },
                    placeholder: 'Select',
                    options: newDef.allowNulls ? [{ value: '<blank>', label: '<blank>' }, ...newDef.fromEntity.asOptionList(null, newDef.omitEntitySort ? false : true)] : newDef.fromEntity.asOptionList(null, newDef.omitEntitySort ? false : true)
                };

                if (newDef.allowNulls) {
                    selectFilterOptions.onFilter = (filterValue, data) => {
                        if (filterValue === '<blank>') {
                            return data.filter(cell => cell[newDef.dataField] === null);
                        } else {
                            if (filterValue) {
                                return data.filter(cell => cell[newDef.dataField] === filterValue);
                            } else {
                                return data;
                            }
                        }
                    }
                }

                newDef.filter = selectFilter(selectFilterOptions);
            }
        }

        if (newDef.asUser) {
            newDef.editor = {
                type: Type.SELECT,
                options: EM.users.asOptionList((row) => {
                    return row.Name + ' (' + row.Email + ')';
                }, true)
            };

            if (registerFilter) {
                newDef.filter = selectFilter({
                    getFilter: (filter) => { if (registerFilter) registerFilter(filter); },
                    placeholder: 'Select',
                    options: EM.users.asOptionList((row) => {
                        return row.Name + ' (' + row.Email + ')';
                    }, true)
                });
            }

            newDef.formatter = (cell) => {
                if (!cell) return null;
                let user = EM.users.byId(cell);
                if (!user) return <span className="text-muted">{EM.t('users.userRemoved')}</span>;
                return <span><UserIcon user={user} className="small mr-1" />{newDef.userText || user.Name}</span>
            };

            newDef.csvFormatter = (cell) => {
                let user = EM.users.byId(cell);
                return user ? user.Name : 'User has been removed.';
            };
        }

        if (newDef.asUserName) {
            let usersList;
            // If excludeByFields is set, then we want to exclude users that have any of the fields set to 'false'
            if (newDef.excludeByFields) {
                usersList = EM.users.get().filter((row) => {
                    for (let i = 0; i < newDef.excludeByFields.length; i++) {
                        return row[newDef.excludeByFields[i]];
                    }
                    return true;
            }).map( row => {
                    return { value: row.UserId, label: row.Name + ' (' + row.Email + ')'};
                }).sort((a, b) => a.label.localeCompare(b.label));
            }else{
                usersList = EM.users.asOptionList((row) => {
                    return row.Name + ' (' + row.Email + ')';
                }, true)
            }
            newDef.editor = {
                type: Type.SELECT,
                options: usersList
            };
            if (registerFilter) {
                newDef.filter = selectFilter({
                    getFilter: (filter) => { if (registerFilter) registerFilter(filter); },
                    placeholder: 'Select',
                    options: EM.users.asOptionList((row) => {
                        return row.Name + ' (' + row.Email + ')';
                    }, true)
                });
            }

            newDef.formatter = newDef.csvFormatter = newDef.sortValue = (cell) => {
                let user = EM.users.byId(cell);
                return user ? user.Name : 'User has been removed.';
            };
        }

        if (newDef.asUserIcon) {
            newDef.editor = null;
            newDef.editable = false;
            newDef.formatter = (cell) => {
                let user = EM.users.byId(cell);
                return <span><UserIcon user={user} className="small mr-1" /></span>
            };
            newDef.csvFormatter = (cell) => {
                let user = EM.users.byId(cell);
                return user ? user.Name : 'Unknown: ' + cell;
            };

            if (registerFilter) {
                newDef.filter = selectFilter({
                    getFilter: (filter) => { if (registerFilter) registerFilter(filter); },
                    placeholder: 'Select',
                    options: EM.users.asOptionList((row) => {
                        return row.Name + ' (' + row.Email + ')';
                    }, true)
                });
            }
        }

        if (newDef.asPermission) {
            newDef.editor = {
                type: Type.SELECT,
                options: [
                    { value: 1, label: 'Subscriber (Read-Only)' },
                    { value: 50, label: 'Editor (Read/Write)' },
                    { value: 100, label: 'Domain Admin (Administrative)' }
                ]
            };
            newDef.formatter = newDef.csvFormatter = (cell) => {
                let permission = EM.permissionLookup.byId(cell);
                return permission ? permission.Name : cell;
            };

            if (registerFilter) {
                newDef.filter = selectFilter({
                    getFilter: (filter) => { if (registerFilter) registerFilter(filter); },
                    placeholder: 'Select',
                    options: [
                        { value: 1, label: 'Subscriber' },
                        { value: 50, label: 'Editor' },
                        { value: 100, label: 'Domain Admin' }
                    ]
                });
            }
        }

        if (newDef.asActive && !newDef.formatter && !newDef.editor) {
            newDef.editorRenderer = (editorProps, value, row, column, rowIndex, columnIndex) => {
                return <ActiveEditField {...editorProps} value={value} />
            };
            newDef.formatter = newDef.csvFormatter = (cell) => {
                if (typeof cell === 'undefined' || cell === null) return '';
                return cell.toString().toLowerCase() === 'true' ? 'Yes' : 'No'
            };

            if (registerFilter) {
                newDef.filter = selectFilter({
                    getFilter: (filter) => { if (registerFilter) registerFilter(filter); },
                    placeholder: 'Select',
                    options: [
                        { value: true, label: 'Yes' },
                        { value: false, label: 'No' },
                    ]
                });
            }
        }

        if (newDef.asWorkItem && !newDef.editor) {
            newDef.editorRenderer = (editorProps, value, row, column, rowIndex, columnIndex) => {
                return <WorkItemSelector {...editorProps} value={value} />
            };
            if (!newDef.formatter) {
                newDef.formatter = newDef.csvFormatter = (cell) => {
                    return cell;
                };
                newDef.csvFormatter = newDef.csvFormatter = (cell) => {
                    return cell ? cell : '';
                };
            }
        }

        if (newDef.asPercentage && !newDef.formatter && !newDef.editor) {
            newDef.editorRenderer = (editorProps, value, row, column, rowIndex, columnIndex) => {
                return <PercentageEditField {...editorProps} value={value} defaultPercentage={(newDef.defaultPercentage || 0)} />
            };
            newDef.formatter = newDef.csvFormatter = (cell) => {
                if (!cell) return '';
                return (cell * 100).toFixed(0);
            };
        }

        if (newDef.asDate && !newDef.formatter && !newDef.editor) {
            newDef.editorRenderer = (editorProps, value, row, column, rowIndex, columnIndex) => {
                return <DateEditField {...editorProps} value={value} />
            };
            newDef.formatter = (cell, row) => {
                if (!cell) return;
                let value = Dates.toMonthYearStrShort(Dates.fromISO(cell));
                if (newDef.highlightChangeAs){
                    if (row[newDef.highlightChangeAs]) {
                        return (<span className="highlight d-inline-block pl-1 pr-1">{value}</span>);
                    } else {
                        return value;
                    }
                }else{
                    return value;
                }
            };
            newDef.csvFormatter = (cell) => {
                if (!cell) return '';
                return Dates.toDateShort(Dates.fromISO(cell));
            };
            newDef.filterValue = (cell) => {
                if (!cell) return null;
                return Dates.fromISO(cell).toJSDate();
            };

            if (registerFilter) {
                newDef.filter = dateFilter({
                    placeholder: 'Date',
                    withoutEmptyComparatorOption: true,
                    comparators: [Comparator.EQ, Comparator.GT, Comparator.LT],
                    getFilter: (filter) => { if (registerFilter) registerFilter(filter, 'date'); },
                });
            }
        }

        if (newDef.asExpression && !newDef.formatter && !newDef.editor) {
            newDef.editorRenderer = (editorProps, value, row, column, rowIndex, columnIndex) => {
                if (newDef.onStartEdit) {
                    newDef.onStartEdit(editorProps, value, row, column, rowIndex, columnIndex);
                }
                return <ExpressionEditField {...editorProps} value={value} />
            };
            newDef.formatter = (cell) => {
                let expressionLocal = new ExpressionLocal(cell);
                let exprStr = expressionLocal.renderExpressionStr('');
                exprStr = exprStr.replace(/\n/g, '');
                return exprStr;
            };
            newDef.csvFormatter = (cell) => {
                return cell || '';
            };
            newDef.classes = 'column-code';
        }

        if (newDef.asFeature && !newDef.formatter && !newDef.editor) {
            newDef.editorRenderer = (editorProps, value, row, column, rowIndex, columnIndex) => {
                if (newDef.onStartEdit) {
                    newDef.onStartEdit(editorProps, value, row, column, rowIndex, columnIndex);
                }
                return <PopoutEditField {...editorProps} value={value} />
            };
            newDef.formatter = (cell) => {
                let jsonObj;
                try {
                    jsonObj = JSON.parse(cell);
                } catch (e) { }
                if (!jsonObj) {
                    return cell ? 'Invalid JSON' : '';
                }
                let output = '';
                Object.keys(jsonObj).forEach(key => {
                    let tmp = [];
                    Object.keys(jsonObj[key]).forEach(feat => {
                        if (jsonObj[key][feat]) {
                            tmp.push(feat.capitalize());
                        }
                    });
                    if (tmp.length > 0) {
                        output += (key === '*' ? 'All' : key) + ': ' + tmp.join(', ');
                    }
                });
                return output;
            };
            newDef.csvFormatter = (cell) => {
                return cell || '';
            };
            newDef.classes = 'column-pre';
        }

        if (newDef.asOptions && !newDef.formatter && !newDef.editor) {
            newDef.editorRenderer = (editorProps, value, row, column, rowIndex, columnIndex) => {
                if (newDef.onStartEdit) {
                    newDef.onStartEdit(editorProps, value, row, column, rowIndex, columnIndex);
                }
                return <PopoutEditField {...editorProps} value={value} />
            };
            newDef.formatter = (cell) => {
                let jsonObj;
                try {
                    jsonObj = JSON.parse(cell);
                } catch (e) { }

                if (!jsonObj) {
                    return cell ? 'Invalid JSON' : <input type="button" className="btn btn-sm btn-secondary" value="Edit" />;
                } else {
                    return <>
                        <input type="button" className="btn btn-sm btn-secondary" value="Edit" />
                        {jsonObj.color ?
                            <input type="color" className="color" value={jsonObj.color} disabled />
                            : null}
                    </>
                }
            };
            newDef.csvFormatter = (cell) => {
                return cell || '';
            };
            newDef.classes = 'column-options';
        }

        if (newDef.asReferenceTable && !newDef.formatter && !newDef.editor) {
            newDef.editorRenderer = (editorProps, value, row, column, rowIndex, columnIndex) => {
                if (newDef.onStartEdit) {
                    newDef.onStartEdit(editorProps, value, row, column, rowIndex, columnIndex);
                }
                return <PopoutEditField {...editorProps} value={value} />
            };
            newDef.formatter = (cell) => {
                return <button className="btn btn-sm btn-primary" disabled={newDef.hidden}>{EM.t('util.table.view')}</button>
            }
            newDef.csvFormatter = (cell) => {
                return cell || '';
            };
            newDef.headerClasses = 'column-functions';
            newDef.classes = 'column-functions';
            newDef.csvExport = false;
        }

        if (newDef.asAssignmentStatus && !newDef.formatter && !newDef.editor) {
            newDef.editorRenderer = (editorProps, value, row, column, rowIndex, columnIndex) => {
                return <AssignmentStatusEditField {...editorProps} value={value} />
            };

            newDef.formatter = newDef.csvFormatter = (cell) => {
                return cell ? cell : 'Assigned';
            };

            if (registerFilter) {
                newDef.filter = selectFilter({
                    getFilter: (filter) => { if (registerFilter) registerFilter(filter); },
                    placeholder: 'Select',
                    options: [
                        { value: 'Assigned', label: 'Assigned' },
                        { value: 'Locked', label: 'Locked' },
                    ],
                    onFilter: (filterVal, data) => {
                        if (!filterVal) return data;
                        return data.filter(item => {
                            if (filterVal === 'Assigned'){
                                return item.Status === null || item.Status === filterVal;
                            }else{
                                return item.Status === filterVal
                            }
                        });
                    }
                });
            }
        }

        if (newDef.asAssignmentLabel) {
            newDef.formatter = newDef.csvFormatter = (cell) => {
                return cell;
            };

            newDef.editorRenderer = (editorProps, value, row, column, rowIndex, columnIndex) => {
                return <AssignmentLabelEditField {...editorProps} value={value} />
            };
        }

        if (newDef.asDescription) {
            newDef.editor = {
                maxLength: newDef.characterLimit || 200,
                type: Type.TEXTAREA
            }
        }

        if (newDef.isReference) {
            newDef.isDummyField = true;
            newDef.headerClasses = 'column-reference';
            newDef.classes = 'column-reference';
            newDef.editable = false;
            newDef.csvExport = false;
        }

        if (newDef.isDateReference) {
            newDef.headerClasses = newDef.headerClasses || 'column-reference';
            newDef.classes = newDef.classes || 'column-reference';
            newDef.editable = false;
            if (typeof newDef.csvExport === 'undefined') newDef.csvExport = false;
            newDef.formatter = (cell) => {
                if (!cell) return;
                return newDef.isDateReference === 'tight' ? Dates.isoToDateTime(cell, Dates.DATE_SHORT) : Dates.isoToDateTime(cell);
            };
            newDef.csvFormatter = (cell) => {
                if (!cell) return;
                let rawDate = Dates.isoToDateTime(cell, Dates.DATETIME_LONG);
                return rawDate.replace(/(\d{4}),\s/gm, '$1 '); //This could cause trouble for ex-us folks. 
            };
            newDef.sortValue = (cell) => {
                if (!cell) return;
                return Dates.fromISO(cell).toMillis();
            };
            newDef.filterValue = (cell) => {
                if (!cell) return undefined;
                return Dates.fromISO(cell).toJSDate();
            };

            if (registerFilter) {
                newDef.filter = dateFilter({
                    placeholder: 'Date',
                    withoutEmptyComparatorOption: true,
                    comparators: [Comparator.EQ, Comparator.GT, Comparator.LT],
                    getFilter: (filter) => { if (registerFilter) registerFilter(filter, 'date'); },
                });
            }
        }

        if (newDef.asFunctions) {
            newDef.isDummyField = true;
            newDef.dataField = 'functions' + columnIndex;
            newDef.headerClasses = 'column-functions';
            newDef.classes = 'column-functions';
            newDef.editable = false;
            newDef.csvExport = false;
        }

        if (newDef.asMeta) {
            newDef.headerClasses = 'column-meta';
            newDef.classes = 'column-meta';
            newDef.editable = false;
            newDef.creatable = false;
            newDef.isMeta = true;
            newDef.unhideable = true;
            if (typeof newDef.csvExport === 'undefined') newDef.csvExport = false;
        }

        if (newDef.width) {
            newDef.classes = (newDef.classes ? newDef.classes + ' ' : '') + 'width-' + newDef.width;
            newDef.headerClasses = (newDef.headerClasses ? newDef.headerClasses + ' ' : '') + 'width-' + newDef.width;
        }

        if (newDef.validators) {
            newDef.validator = (newValue, row, column) => {
                let valResult = Validate(newValue, newDef.validators);
                if (valResult === null) {
                    return true;
                } else {
                    window.setTimeout(() => { EM.setError(valResult) }, 100);
                    return { valid: false, message: valResult }
                }
            }
        }

        if (newDef.editable === false) {
            newDef.classes = (newDef.classes ? newDef.classes + ' ' : '') + 'readonly';
        }

        if (!newDef.csvFormatter) {
            newDef.csvFormatter = (cell) => {
                if (cell) {
                    return cell;
                } else {
                    if (cell === 0) {
                        return '0';
                    } else {
                        return '';
                    }
                }
            };
        }

        if (!newDef.hasOwnProperty('filter') && newDef.filterOptions && registerFilter) {
            if (newDef.filterOptions.dynamicEntity) {
                let filterOptions = newDef.filterOptions.dynamicEntity.asOptionList('Name', false, 'Name');
                filterOptions.sort((a, b) => a.value.localeCompare(b.value));
                newDef.filter = selectFilter({
                    getFilter: (filter) => { if (registerFilter) registerFilter(filter); },
                    placeholder: 'Select',
                    options: filterOptions
                });
            }

            if (newDef.filterOptions.number) {
                newDef.filter = numberFilter({
                    getFilter: (filter) => { if (registerFilter) registerFilter(filter, 'number'); },
                    withoutEmptyComparatorOption: true,
                    comparators: [Comparator.EQ, Comparator.GT, Comparator.LT],
                    placeholder: 'Value',
                    onFilter: (newDef.asPercentage ? (filterVal, data) => {
                        let number = filterVal.number;
                        let comparator = filterVal.comparator;

                        return data.filter(item => {
                            if (number === '' || !comparator) return true;
                            let cell = item[newDef.dataField];
                            switch (comparator) {
                                case '=': {                                    
                                    return cell == number / 100; // eslint-disable-line eqeqeq
                                }
                                case '>': {
                                    return cell > number / 100;
                                }
                                case '<': {
                                    return cell < number / 100;
                                }
                                default: {
                                    console.error('Number comparator provided is not supported');
                                    return true;
                                }
                            }
                        });
                    } : null)
                });
            }

            if (newDef.filterOptions.text) {
                newDef.filter = textFilter({
                    getFilter: (filter) => { if (registerFilter) registerFilter(filter); },
                    placeholder: 'Value'
                });
            }

            if (newDef.filterOptions.bool) {
                newDef.filter = selectFilter({
                    getFilter: (filter) => { if (registerFilter) registerFilter(filter); },
                    placeholder: 'Select',
                    options: [
                        { value: true, label: 'Yes' },
                        { value: false, label: 'No' },
                    ]
                });
            }
        }


        if (!newDef.editor && !newDef.editorRenderer && newDef.editable !== false && newDef.characterLimit) {
            newDef.editor = {
                maxLength: newDef.characterLimit
            }
        }

        if (newDef.hidden){
            newDef.headerClasses = newDef.headerClasses + ' d-none';
            newDef.classes = newDef.classes + ' d-none';
        }

        return newDef;
    });
}